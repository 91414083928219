<template>
    <div>
      <header-component active="/property" isCurrent="1"></header-component>
      <!-- <product-component isCurrent="1"></product-component> -->
      <div class="property-content">
            <div class="banner">
                <div class="banner_box animate-text">
                    <div class="banner_box-white">
                    <div class="banner_title banner_big_title">
                        智慧物业
                    </div>
                    <div class="banner_subTitle">
                        建立企业统一规范的物业基础管理体系，适应多种收费场景、灵活多样的收费渠道，有效提高物业各项业务的综合协作能力和服务水平，实现物业企业和商业模式的全新升级。
                    </div>
                    </div>
                </div>
            </div>

            <div class="product-net">
            <div class="property-net">

                <div class="property-net-list isbg">
                    <div class="box-width">
                    <el-row :gutter="20">
                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                            <div class="property-net-img slide-in-left">
                                <img src="../../assets/pro/property-mobile.png" alt="">
                                <!-- <el-image :src="require('../../assets/pro/property-mobile.png')"></el-image> -->
                            </div>
                        </el-col>

                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                            <div class="slide-in-right">
                            <div class="title bigTop">移动业主端</div>
                            <div class="details">通过访问微信公众号页面，业主可查看物业发布的公告、活动，可进行房屋绑定、物业缴费、预存缴费、工单报修、在线验房、积分兑换礼品等</div>
                            <div class="classify">
                                <el-row :gutter="20">
                                    <el-col :xs="6" :sm="6" :md="4" :lg="4" :xl="4" v-for="(item, index) in mobileList" :key="index">
                                        <div class="classify-hover">
                                            <img :src="item.icon" alt="" class="sway">
                                            <div class="name">{{ item.name }}</div>
                                        </div>
                                    </el-col>
                                </el-row>
                            </div>
                            </div>
                        </el-col>
                    </el-row> 
                    </div> 
                </div>
                
                <div class="property-net-list box-width">
                    <el-row :gutter="20">
                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                            <div class="slide-in-left">
                            <div class="title">管理后台</div>
                            <div class="details">对后台管理区分管理层级及功能权限，项目管理者可对楼盘、楼栋、房间进行管理，可按房间设置收费规则，进行收付款，生成收费通知单。支持多种收款方式：现金收款、二维码收款、POS机收款等。高层管理者权限，可对所有楼盘进行管理，可查看所有楼盘的收费情况</div>
                            <div class="classify">
                                <el-row :gutter="20">
                                    <el-col :xs="6" :sm="6" :md="4" :lg="4" :xl="4" v-for="(item, index) in pcList" :key="index">
                                        <div class="classify-hover">
                                            <img :src="item.icon" alt="" class="sway">
                                            <div class="name">{{ item.name }}</div>
                                        </div>
                                    </el-col>
                                </el-row>
                            </div>
                            </div>
                        </el-col>
                        
                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                            <div class="property-net-img slide-in-right"  style="width: 100%;">
                                <img src="../../assets/pro/property-pc.png" alt="">
                            </div>
                        </el-col>
                    </el-row>  
                </div>
            </div>
            </div>

            <div class="product_strength">
            <div class="property_strength">
                <div class="box-width">
                    <div class="property_strength_title public_title">产品优势</div>
                    <el-row :gutter="25">
                        <div class="property_strength_box">
                            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" v-for="(item,index) in strengthList" :key="index">
                                <div class="property_strength_box_list">
                                    <img :src="item.icon">
                                    <h4>{{item.name}}</h4>
                                    <div class="property_strength_t">
                                        
                                        <span>{{item.info}}</span>
                                    </div>
                                </div>
                            </el-col>
                        </div>
                    </el-row>
                </div>
            </div>
            </div>

            <div class="property_scene box-width">
                <div class="property_scene_title public_title">应用场景</div>
                <div class="property_scene_box">
                    <el-row :gutter="25">
                        <el-col :xs="12" :sm="24" :md="12" :lg="6" :xl="6" v-for="item in sceneList" :key="item.id">
                            <div class="property_scene_box_list scale-img">
                                <img :src="item.imgUrl">
                                <div class="property_scene_box_list_t">
                                    {{item.title}}
                                </div>
                            </div>
                            
                        </el-col>
                    </el-row>
                </div>
            </div>
            
      </div>
      <footer-component></footer-component>
    </div>
</template>

<script>
export default {
    name: 'app_Property',
    data () {
        return {
            mobileList: [
                {icon: require('../../assets/icon/property_icon1.png'), name: '房屋定制'},
                {icon: require('../../assets/icon/property_icon2.png'), name: '物业缴费'},
                {icon: require('../../assets/icon/property_icon3.png'), name: '预存缴费'},
                {icon: require('../../assets/icon/property_icon4.png'), name: '工单报修'},
                {icon: require('../../assets/icon/property_icon5.png'), name: '预约收房'}
            ],
            pcList:[
                {icon: require('../../assets/icon/property_icon6.png'), name: '楼盘管理'},
                {icon: require('../../assets/icon/property_icon7.png'), name: '住户管理'},
                {icon: require('../../assets/icon/property_icon8.png'), name: '收费设置'},
                {icon: require('../../assets/icon/property_icon9.png'), name: '付款管理'}
            ],
            strengthList: [
                {icon: require('../../assets/icon/property_strength1.png'), name: 'BS架构模式', info: '只要有网络，即可使用浏览器随时随地访问 系统'},
                {icon: require('../../assets/icon/property_strength2.png'), name: '多种催费方式', info: '支持微信和手机短信发送催缴信息，多方位提醒业主缴费，提升物业收费率 '},
                {icon: require('../../assets/icon/property_strength3.png'), name: '计费模式多样化', info: '支持计费规则自定义，可根据不同的收费项目设置不同的计费规则'},
                {icon: require('../../assets/icon/property_strength4.png'), name: '收款方式多样化', info: '支持多种收款方式，可根据实际情况来使用 不同的收款方式 '},
                {icon: require('../../assets/icon/property_strength5.png'), name: '多维度运营报表', info: '多种统计报表，可按不同的需求来查看收款情况、应交已收情况、欠款情况、应交款情况、押金情况、楼管员收款情况'},
                {icon: require('../../assets/icon/property_strength6.png'), name: '实时监控楼盘情况', info: '物业公司可实时监控各楼盘的管理情况、收费 '}
            ],
            sceneList: [
                {imgUrl: require('../../assets/pro/property-scene1.png'), title: '商圈'},
                {imgUrl: require('../../assets/pro/property-scene2.png'), title: '写字楼'},
                {imgUrl: require('../../assets/pro/property-scene3.png'), title: '产业园'},
                {imgUrl: require('../../assets/pro/property-scene4.png'), title: '住宅'},
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
    .banner{background: url(../../assets/banner/property_b.png) no-repeat 80% center;background-size: cover;height: 557px;
        &_box{width: 30%;padding-top: 5%;}
        &_title{color: #fff;margin-bottom: 10px;}
    }

    .property-net .isbg{background: url(../../assets/banner/property_bg.png)no-repeat center;}
    .bigTop{margin-top: 180px!important;}
    
    .mobile {
        .banner{
            &_box{width: 95%;}
        }
        .bigTop{margin-top: 60px!important;}
    }
</style>