<template>
  <div id="app" :class="{ mobile: isMobile }">
    <router-view/>
    <!-- <img alt="Vue logo" src="./assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  data() {
    return {
      isMobile: false
    }
  },
  // components: {
  //   HelloWorld
  // }
  methods: {
    checkMobile() {
      this.isMobile = window.innerWidth < 768;
    }
  },
  created() {
    this.checkMobile();
    window.addEventListener('resize', this.checkMobile);
  },
  destroyed() {
    window.removeEventListener('resize', this.checkMobile);
  }
}
</script>

<style>
body,html {
  margin: 0;
  width: 100%;
  color: #333;
  overflow-x: hidden;
}
li{list-style: none;}
li a{text-decoration: none;}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  width: 100%;
  overflow-x: hidden;

}
</style>
