<template>
<div class="left-float">
    <div class="float-box">
        <div class="list">
            <div class="l">
                <el-popover
                placement="left"
                width="150"
                trigger="click">    
                    <div class="l-1">
                        <img src="../assets/pro/qrCode.png">
                        <div class="text">客服微信</div>
                    </div>        
                    <img slot="reference" src="../assets/icon/icon-weixin.png">
                </el-popover>
            </div>
            <div class="l">
                <el-popover
                placement="left"
                width="250"
                trigger="click">    
                    <div class="l-2">
                        <div class="i">
                            <div class="f">
                                <img src="../assets/icon/icon-phone.png">
                                <div class="n">咨询电话</div>
                            </div>
                            <div class="t">180-7818-7508（客户经理） </div>
                        </div>
                        <div class="i">
                            <div class="f">
                                <img src="../assets/icon/icon-mail.png">
                                <div class="n">邮箱地址</div>
                            </div>
                            <div class="t">liyuqi@kaiyun.tech </div>
                        </div>
                    </div>        
                    <img slot="reference" src="../assets/icon/icon-service.png">
                </el-popover>
            </div>
            <div class="l"  @click="experienceDialogVisible = true">
                <img src="../assets/icon/icon-msg.png">
            </div>
        </div>
        
        <el-backtop :bottom="220" :right="50">
            <div class="top">
                <img src="../assets/icon/icon-top.png">
            </div>
        </el-backtop> 
        
    </div>

    <el-dialog
        title="免费体验"
        top="200px"
        append-to-body
        :width="dialogWidth"
        :close-on-click-modal="false"
        :visible.sync="experienceDialogVisible">
        <experienceComponent @experienceVisible="experienceVisible"></experienceComponent>
    </el-dialog>
    
</div>
</template>

<script>
import experienceComponent  from "./experience.vue";
export default {
    name: 'leftFloat-component',
    props: {
        isCurrent: String
    },
    data () {
        return {
            experienceDialogVisible: false,
            dialogWidth: '30%',
        }
    },
    methods: {
      experienceVisible(){
        this.experienceDialogVisible = false;
      },
      initDialogWidth(){
        const width = document.body.clientWidth;
        if(width < 600){
          this.dialogWidth = '90%';
        }
      }
    },
    mounted() {
      this.initDialogWidth();
    },
    watch: {
      '$store.state.dialogWidth': function(val) {
        console.log(val)
        this.dialogWidth = val;
      },
    },
    components: {
      experienceComponent
    }
}
</script>

<style lang="scss" scoped>
    .float-box{position: fixed;top: 60%;right: 50px;margin-top: -100px;z-index: 1;}
    .list{width: 55px;background: #fff;padding:20px 0;border-radius: 30px;box-shadow: 0 0 10px rgba(0,0,0,.2);text-align: center;
        .l{width: 34px;height: 34px;margin: 0 auto 12px;
            img{width: 100%;height: 100%;}
        }
        .l:last-child{margin: 0 auto;}
        .l:hover{cursor: pointer;}
        
    }


    .l-1{
        img{width: 147px;height: 147px;}
        .text{text-align: center;padding-bottom: 5px;}
    }

    .l-2{padding: 10px;
        .i{margin-bottom: 15px;
            .f{display: flex;align-items: center;margin-bottom: 3px;
                .n{font-size: 16px;color: #333;font-weight: bold;}
                img{width:30px;height: 30px;}
            }
            .t{font-size: 14px;color: #666;margin-left: 30px;}
            
        }
        
    }

    .top{width: 55px;height: 55px;background: #fff;border-radius: 50%;box-shadow: 0 0 10px rgba(0,0,0,.2);text-align: center;line-height: 55px;
        img{width: 34px;height: 34px;margin-top: 10.5px;}
    }
    .top:hover{cursor: pointer;}
    /deep/ .el-backtop{width: 55px;height: 55px;}
    .mobile{
        .float-box{top: 55%;}
    }
</style>

