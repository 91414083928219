<template>
    <div>
      <header-component active="/property" isCurrent="3"></header-component>
      <!-- <product-component></product-component> -->
      <div class="property-content">
            <div class="banner">
                <div class="banner_box animate-text">
                    <div class="banner_box-black">
                    <div class="banner_title banner_big_title">
                        智能工单
                    </div>
                    <div class="banner_subTitle">
                        助力企业跨部门高效协作，灵活智能流转，全面监控流程节点。支持接入物业管理平台，逐步搭建物业客户服务管理平台，为物业服务管理提供数据支撑，提供更统一精准、优质的客户服务。
                    </div>
                    </div>
                </div>
            </div>

            <div class="product-net">
            <div class="property-net">

                <div class="property-net-list isbg" style="padding: 20px 0 0;">
                    <div class="box-width">
                    <el-row :gutter="20">
                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                            <div class="property-net-img slide-in-left">
                                <img src="../../assets/pro/work-mobile.png" alt="">
                            </div>
                        </el-col>

                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                            <div class="slide-in-right">
                            <div class="title bigTop">移动客户端</div>
                            <div class="details">客户登录后，可自助提单，实时查看工单状态和处理情 况，完成工单后可评价工单服务品质 </div>
                            <div class="classify">
                                <el-row :gutter="20">
                                    <el-col :xs="6" :sm="6" :md="4" :lg="4" :xl="4" v-for="(item, index) in mobileList" :key="index">
                                        <div class="classify-hover">
                                            <img :src="item.icon" alt="" class="sway">
                                            <div class="name">{{ item.name }}</div>
                                        </div>
                                    </el-col>
                                </el-row>
                            </div>
                            </div>
                        </el-col>
                    </el-row> 
                    </div> 
                </div>

                <div class="property-net-list isbg2" style="padding: 0;">
                    <el-row :gutter="20">
                        <el-col :xs="24" :sm="21" :md="11" :lg="11" :xl="10">
                            <div class="box-width topCenter">
                            <div class="title whiteColor">移动管理端</div>
                            <div class="details whiteColor">员工在移动管理端跟踪工单信息，简化流转，在线抢单和接单，便捷工单管理，高效管理。支持有偿工单线上收款，生成收款二维码，收款后实时到企业账户</div>
                            <div class="classify">
                                <el-row :gutter="20">
                                    <el-col :xs="6" :sm="6" :md="4" :lg="4" :xl="4" v-for="(item, index) in manageList" :key="index">
                                        <div class="classify-hover">
                                            <img :src="item.icon" alt="" class="sway">
                                            <div class="name whiteColor">{{ item.name }}</div>
                                        </div>
                                    </el-col>
                                </el-row>
                            </div>
                            </div>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="13" :lg="13" :xl="14">
                            <div class="property-net-img scale-img" style="margin-top: 0;width: 100%;display: grid;">
                                <img src="../../assets/pro/work-manage.png" alt="">
                            </div>
                        </el-col>
                    </el-row>
                </div>

                <div class="property-net-list box-width">
                    <el-row :gutter="20">
                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                            <div class="property-net-img slide-in-left" style="width: 100%;">
                                <img src="../../assets/pro/work-pc.png" alt="">
                            </div>
                        </el-col>

                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                            <div class="slide-in-right">
                            <div class="title">管理后台</div>
                            <div class="details">统一管理企业工单信息，配置工单基础信息，根据不同类型统计工 单的状态及收费情况，让企业高层实时掌握工单处理情况 跟踪工单状态，灵活调度工单。支持定时批量发布工单信息，根据职位快捷指派任务。</div>
                            </div>
                        </el-col>
                    </el-row> 
                </div>
            </div>
            </div>

            <div class="product_strength">
            <div class="property_strength work_strength">
                <div class="box-width">
                    <div class="property_strength_title public_title">产品优势</div>
                    <el-row :gutter="25">
                        <div class="property_strength_box">
                            <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6" v-for="(item,index) in strengthList" :key="index">
                                <div class="property_strength_box_list">
                                    <img :src="item.icon">
                                    <h4>{{item.name}}</h4>
                                    <div class="property_strength_t">
                                        <span>{{item.info}}</span>
                                    </div>
                                </div>
                            </el-col>
                        </div>
                    </el-row>
                </div>
            </div>
            </div>

            <div class="property_scene box-width">
                <div class="property_scene_title public_title">应用场景</div>
                <div class="property_scene_box">
                    <el-row :gutter="25">
                        <el-col :xs="12" :sm="24" :md="12" :lg="6" :xl="6" v-for="item in sceneList" :key="item.id">
                            <div class="property_scene_box_list scale-img">
                                <img :src="item.imgUrl">
                                <div class="property_scene_box_list_t">
                                    {{item.title}}
                                </div>
                            </div>
                            
                        </el-col>
                    </el-row>
                </div>
            </div>
            
      </div>
      <footer-component></footer-component>
    </div>
</template>

<script>
export default {
    name: 'app_Workorder',
    data () {
        return {
            mobileList: [
                {icon: require('../../assets/icon/work_icon1.png'), name: '自助提单'},
                {icon: require('../../assets/icon/work_icon2.png'), name: '工单状态'},
                {icon: require('../../assets/icon/work_icon3.png'), name: '评价工单'}
            ],
            manageList: [
                {icon: require('../../assets/icon/work_icon4.png'), name: '在线接单'},
                {icon: require('../../assets/icon/work_icon5.png'), name: '线上收款'},
                {icon: require('../../assets/icon/work_icon6.png'), name: '实时到账'}
            ],
            strengthList: [
                {icon: require('../../assets/icon/work_strength1.png'), name: '员工工单考核', info: '可设置工单考核规则，根据工单完成情况统计员工分值'},
                {icon: require('../../assets/icon/work_strength2.png'), name: '统一收款渠道', info: '有偿服务工单可在线上进行支付收款透明，避免私下交易'},
                {icon: require('../../assets/icon/work_strength3.png'), name: '定时发布工单', info: '可统一批量发布多条工单，指派给某个职位的员工'},
                {icon: require('../../assets/icon/work_strength4.png'), name: '修改信息审批', info: '修改工单信息需要审批才生效，规范工单管理流程'}
            ],
            sceneList: [
                {imgUrl: require('../../assets/pro/work-scene1.png'), title: '客户服务'},
                {imgUrl: require('../../assets/pro/work-scene2.png'), title: '售后服务'},
                {imgUrl: require('../../assets/pro/work-scene3.png'), title: '设备维护'},
                {imgUrl: require('../../assets/pro/work-scene4.png'), title: '物业管理'}
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
    .banner{background: url(../../assets/banner/workorder_b.png) no-repeat 80% center;background-size: cover;height: 557px;
        &_box{width: 30%;padding-top: 5%;}
        &_title{color: #fff;margin-bottom: 10px;}
    }
    .property-net .isbg{background: #fafafa;}
    .property-net .isbg2{background: #0066CB;}
    .work_strength{background: url(../../assets/banner/work_strength_bg.png)no-repeat center center!important;background-size: cover!important;}
    // .product-net .property-net-list .title{margin-top: 150px;}
    .bigTop{margin-top: 250px!important;}
    .mobile {
        .banner{
            &_box{width: 95%;}
        }
        .bigTop{margin-top: 60px!important;}
    }
</style>