import Vue from 'vue'
import VueRouter from 'vue-router';
 
import HomeView from  '@/views/index.vue'
import AboutView from  '@/views/about.vue'
import propertyView from '@/views/product/property.vue';
import oaView from '@/views/product/oa.vue';
import workorderView from '@/views/product/workorder.vue';
import chargingView from '@/views/product/charging.vue';
import parkingView from '@/views/product/parking.vue';
import doorView from '@/views/product/door.vue';
import dangjianView from '@/views/product/dangjian.vue';
import echartDataView from '@/views/product/echartData.vue';
import paymentView from '@/views/product/payment.vue';
import assetView from '@/views/product/asset.vue';

Vue.use(VueRouter)

const routes = [
    
    {
        path:'/',
        name: 'Home',
        component: HomeView 
    },
    {
        path:'/about',
        name: 'About',
        component: AboutView 
    },
    {
        path:'/property',
        name: 'Property',
        component: propertyView 
    },
    {
        path:'/oa',
        name: 'Oa',
        component: oaView 
    },
    {
        path:'/workorder',
        name: 'Workorder',
        component: workorderView 
    },
    {
        path:'/charging',
        name: 'Charging',
        component: chargingView 
    },
    {
        path:'/parking',
        name: 'Parking',
        component: parkingView 
    },
    {
        path:'/door',
        name: 'Door',
        component: doorView 
    },
    {
        path:'/dangjian',
        name: 'Dangjian',
        component: dangjianView 
    },
    {
        path:'/payment',
        name: 'Payment',
        component: paymentView 
    },
    {
        path:'/echartData',
        name: 'EchartData',
        component: echartDataView 
    },
    {
        path:'/asset',
        name: 'Asset',
        component: assetView 
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
  })

export default router