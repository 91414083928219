<template>
    <div>
      <header-component active="/property" isCurrent="10"></header-component>
      <!-- <product-component></product-component> -->
      <div class="property-content">
            <div class="banner">
                <div class="banner_box animate-text">
                    <div class="banner_box-black">
                    <div class="banner_title banner_big_title">
                        数字资产管理系统
                    </div>
                    <div class="banner_subTitle">
                        数字资产管理系统是一种综合性的信息化解决方案，旨在帮助企业对其拥有各类资产进行高效、精确和全面的管理，它涵盖了从资产的入库登记、分类、调配、使用、维修，到报废处置的整个生命周期。
                    </div>
                    </div>
                </div>
            </div>

            <div class="product-net">
            <div class="property-net">

                <div class="property-net-list box-width">
                    <el-row :gutter="20">
                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                            <div class="property-net-img slide-in-left">
                                <img src="../../assets/pro/asset-mobile.png" alt="">
                            </div>
                        </el-col>

                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                            <div class="slide-in-right">
                            <div class="title bigTop">资产盘点</div>
                            <div class="details">
                                提供手机端资产盘点，可扫码识别资产标签，随时随地通过手机进行盘点操作，不受时间和地点的限制。实时性高，盘点数据能够即时上传至服务器，方便管理人员及时获取最新的盘点信息。手机端资产盘点大大提高了盘点工作的效率和准确性，降低了人工成本和错误率，为企业的资产管理提供了更加便捷和高效的手段。
                            </div>
                            <!-- <div class="classify">
                                <el-row :gutter="20">
                                    <el-col :xs="6" :sm="6" :md="4" :lg="4" :xl="4" v-for="(item, index) in mobileList" :key="index">
                                        <div class="classify-hover">
                                            <img :src="item.icon" alt="">
                                            <div class="name">{{ item.name }}</div>
                                        </div>
                                    </el-col>
                                </el-row>
                            </div> -->
                            </div>
                        </el-col>
                    </el-row> 
                </div>
                
                <div class="property-net-list isbg">
                    <div class="box-width">
                    <el-row :gutter="20">
                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                            <div class="slide-in-left">
                            <div class="title bigTop">管理系统</div>
                            <div class="details">
                                资产系统管理后台是资产管理系统的核心控制和配置中心。集中存储和管理所有资产相关的数据，包括资产基本信息、入库记使用记录、维修历史、资产履历等。生成多种类型的报表录、如资产清单、资产履历、折旧资产等，提供数据分析功能，帮助管理者了解资产的分布、使用效率、价值变化等情况，为决策提供依据。
                            </div>
                            </div>
                        </el-col>
                        
                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                            <div class="property-net-img slide-in-right" style="width: 100%;">
                                <img src="../../assets/pro/asset-pc.png" alt="">
                            </div>
                        </el-col>
                    </el-row>
                    </div>  
                </div>
            </div>
            </div>
            
            <div class="product_strength">
            <div class="property_strength charging_strength">
                <div class="box-width">
                    <div class="property_strength_title public_title">产品优势</div>
                    <el-row :gutter="25">
                        <div class="property_strength_box">
                            <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6" v-for="(item,index) in strengthList" :key="index">
                                <div class="property_strength_box_list">
                                    <img :src="item.icon">
                                    <h4>{{item.name}}</h4>
                                    <div class="property_strength_t">
                                        <span>{{item.info}}</span>
                                    </div>
                                </div>
                            </el-col>
                        </div>
                    </el-row>
                </div>
            </div>
            </div>

            <div class="property_scene">
                <div class="box-width">
                <div class="property_scene_title public_title">应用场景</div>
                <div class="property_scene_box">
                    <el-row :gutter="25">
                        <el-col :xs="12" :sm="24" :md="12" :lg="6" :xl="4" v-for="item in sceneList" :key="item.id">
                            <div class="property_scene_box_list scale-img">
                                <img :src="item.imgUrl">
                                <div class="property_scene_box_list_t">
                                    {{item.title}}
                                </div>
                            </div>
                            
                        </el-col>
                    </el-row>
                </div>
                </div>
            </div>
            
      </div>
      <footer-component></footer-component>
    </div>
</template>

<script>
export default {
    name: 'app_Asset',
    data () {
        return {
            strengthList: [
                {icon: require('../../assets/icon/asset_strength1.png'), name: '提高管理效率', info: '实现资产信息的录入和更新，减少人工操作的繁琐和错误。快速准确地完成资产盘点，节省时间和人力成本'},
                {icon: require('../../assets/icon/asset_strength2.png'), name: '优化资源配置', info: '清晰了解资产的分布和闲置情况，便于合理调配资源，提高资产利用率。避免重复购置，降低不必要的资产投入'},
                {icon: require('../../assets/icon/asset_strength3.png'), name: '强化资产安全', info: '严格的权限管理和访问控制，保障资产数据的保密性和安全性。对资产的流动和使用进行监控，预防资产丢失和被盗用'},
                {icon: require('../../assets/icon/asset_strength4.png'), name: '规范管理流程', info: '建立标准化的资产管理流程，从入库到报废全过程可控，符合法规和企业制度要求'}
            ],
            sceneList: [
                {imgUrl: require('../../assets/pro/asset-scene1.png'), title: '学校'},
                {imgUrl: require('../../assets/pro/asset-scene2.png'), title: '教育机构'},
                {imgUrl: require('../../assets/pro/asset-scene3.png'), title: '政府机关'},
                {imgUrl: require('../../assets/pro/asset-scene4.png'), title: '事业单位'},
                {imgUrl: require('../../assets/pro/asset-scene5.png'), title: '集团企业'},
                {imgUrl: require('../../assets/pro/asset-scene6.png'), title: '物流仓储'}
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
    .banner{background: url(../../assets/banner/asset_b.png) no-repeat 80% center;background-size: cover;height: 557px;
        &_box{width: 30%;padding-top: 5%;}
        &_title{color: #fff;margin-bottom: 10px;}
        &_subTitle{color: #fff;}
    }
    .property-net .isbg{background: url(../../assets/banner/asset_bg.png)no-repeat center;}
    .property_scene{background: #f6f6f6;}
    .charging_strength{background: #fff!important;}
    .bigTop{margin-top: 180px!important;}
    .mobile {
        .banner{
            &_box{width: 95%;}
        }
        .bigTop{margin-top: 60px!important;} 
    }
</style>