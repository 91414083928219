<template>
    <div>
      <header-component active="/about"></header-component>
        <div class="box-comtainer">

            <div class="banner">
                <div class="banner_box animate-text">
                    <div class="banner_title banner_big_title">
                        真诚 互惠 专注 创新
                    </div>
                    <div class="banner_btn">
                        <!-- <el-button type="primary" size="small" @click="experienceDialogVisible = true">了解我们</el-button> -->
                        <div class="custom-button" @click="experienceDialogVisible = true">免费体验</div>
                    </div>
                </div>
            </div>

            <div class="about_info box-width" id="aboutInfo">
                <div class="public_title">企业简介</div>
                <el-row :gutter="40">
                <div class="about_info_con">
                    <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" >
                    <div class="text">
                        <div class="name">
                            广西众凯科技有限公司
                            <p>Guangxi Zhongkai Technology Co., LTD</p>
                        </div>
                        <div class="info">
                            2015年，我们以数字化服务崭新未来，成立了广西众凯科技有限公司。在“互联网+”的时代背景下，众凯科技结合用户的实际需求，以智能解决方案为切入点，通过物联网+人工智能+大数据监控管理平台等核心技术，自主研发了充电桩产品，为物业公司、充电服务运营、车场运营商、商超公众场所及有需求的企业提供了标准化、组件化、智能化的服务解决方案。
                        </div>
                    </div>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <div>
                        <img src="../assets/pro/about_info.png">
                    </div>
                    </el-col>
                </div>
                </el-row>
                <div class="about_info_box">
                    <el-row :gutter="20">
                        <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6" v-for="(item,index) in infoList" :key="index">
                            <div class="about_info_box_list top-move">
                                <img :src="item.imgUrl">
                                <div class="about_info_t">
                                    <h4>{{ item.title }}</h4>
                                    <span>{{ item.subTitle }}</span>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>

            <div class="about_ccie_pc" id="aboutCcie">
                <div class="about_ccie_pc_title public_title">企业资质</div>
            </div>

            <div class="about_ccie_phone" id="aboutCcie">
                <div class="about_ccie_phone_title public_title">企业资质</div>
                <el-row :gutter="20">
                    <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6" v-for="(item,index) in ccieList" :key="index">
                        <img :src="item.ccieUrl">
                    </el-col>
                </el-row>
            </div>

            <div class="about_business box-width" id="aboutBusiness">
                <div class="about_business_title public_title">经营范围</div>
                <el-row :gutter="25">
                    <!-- <div class="about_business_box"> -->
                        <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" v-for="(item,index) in businessList" :key="index">
                            <div class="about_business_box_list scale-img">
                                <img :src="item.imgUrl">
                                <div class="about_business_t">
                                    <h4>{{item.title}}</h4>
                                    <span>{{item.subTitle}}</span>
                                </div>
                            </div>
                        </el-col>
                    <!-- </div> -->
                </el-row>
            </div>

            <div class="about_serve" id="aboutServe">
                <div class="about_serve_box box-width">
                    <div class="about_serve_title public_title">服务支持</div>
                    <el-row :gutter="25">
                        <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" v-for="(item,index) in aboutServeList" :key="index">
                            <div class="about_serve_box_list top-move">
                                <img :src="item.imgUrl">
                                <div class="about_serve_t">
                                    <h4>{{item.title}}</h4>
                                    <span>{{item.subTitle}}</span>
                                </div>
                            </div>
                        </el-col> 
                    </el-row>
                </div>
            </div>


        </div>

        <el-dialog
        title="免费体验"
        top="200px"
        :width="dialogWidth"
        :close-on-click-modal="false"
        :visible.sync="experienceDialogVisible">
        <experienceComponent @experienceVisible="experienceVisible"></experienceComponent>
        </el-dialog>
        
        <!-- <leftFloat-component></leftFloat-component> -->
        <footer-component></footer-component>
    </div>
</template>

<script>
import experienceComponent  from "../components/experience.vue";
export default {
    name: 'app_about',
    data() {
      return {
        experienceDialogVisible: false,
        dialogWidth: '30%',
        infoList:[
            {imgUrl: require('../assets/icon/about_info1.png'), title:'企业宗旨', subTitle:'难事做易，小事做细，精益求精 '},
            {imgUrl: require('../assets/icon/about_info2.png'), title:'服务精神', subTitle:'能帮就帮'},
            {imgUrl: require('../assets/icon/about_info3.png'), title:'经营理念', subTitle:'真诚、互惠、专注、创新 '},
            {imgUrl: require('../assets/icon/about_info4.png'), title:'企业愿景', subTitle:'实现“能帮就帮”智慧社区解决方案'},
        ],
        ccieList:[
            {ccieUrl: require('../assets/pro/about_ccie1.png')},
            {ccieUrl: require('../assets/pro/about_ccie2.png')},
            {ccieUrl: require('../assets/pro/about_ccie3.png')},
            {ccieUrl: require('../assets/pro/about_ccie4.png')}
        ],
        businessList: [
            {imgUrl: require('../assets/pro/about_business1.png'), title:'系统方案设计', subTitle:'设计定制化解决方案'},
            {imgUrl: require('../assets/pro/about_business2.png'), title:'美术设计', subTitle:'提升用户交互和视觉体验'},
            {imgUrl: require('../assets/pro/about_business3.png'), title:'软件开发', subTitle:'满足多元化的定制功能需求，支持二次开发 '},
            {imgUrl: require('../assets/pro/about_business4.png'), title:'移动开发', subTitle:'公众号、App和小程序移动应用开发'},
            {imgUrl: require('../assets/pro/about_business5.png'), title:'网站开发', subTitle:'企业形象设计，提升企业形象'},
            {imgUrl: require('../assets/pro/about_business6.png'), title:'项目运维', subTitle:'助力信息化建设的稳定运行'}
        ],
        aboutServeList: [
            {imgUrl: require('../assets/icon/about_serve1.png'), title:'全程咨询', subTitle:'提供售前售后一体化咨询服务'},
            {imgUrl: require('../assets/icon/about_serve2.png'), title:'指导培训', subTitle:'提供产品培训，全方位掌握系统功能'},
            {imgUrl: require('../assets/icon/about_serve3.png'), title:'定时检修', subTitle:'定时进行系统维护，保障系统稳定运行'}
        ]
      }
    },
    methods: {
      experienceVisible(){
        this.experienceDialogVisible = false;
      },
      initDialogWidth(){
        const width = document.body.clientWidth;
        if(width < 600){
          this.dialogWidth = '90%';
        }
      }
    },
    mounted() {
      this.initDialogWidth();
    },
    watch: {
      '$store.state.dialogWidth': function(val) {
        console.log(val)
        this.dialogWidth = val;
      },
    },
    components: {
      experienceComponent
    }
}
</script>

<style lang="scss" scoped>
.banner{background: url(../assets/banner/about_b.png) no-repeat 80% center;background-size: cover;}
.about_info{padding-top: 80px;
    &_con{
        .text{
            .name{font-weight: bold;font-size: 19px;
                p{color: #999;margin:0;font-size: 16px;font-weight: normal;}
            }
            .info{color: #666;margin-top: 35px;font-size: 17px;line-height: 33px;}
        }
        img{width: 100%;}
    }
    &_box{
        margin-top: 60px;
        &_list{display: flex;justify-content: center;align-items: center;
            img{width: 70px;height: 70px;margin-right:15px;}
            h4{margin-bottom: 5px;}
            span{color: #666;font-size: 15px;margin-bottom: 10px;}
        }
    }
}

.about_ccie_pc{margin-top: 134px;width: 100%;height: 690px;background: url(../assets/banner/about_ccie.png) no-repeat 80% center;background-size: cover;
    &_title{padding-top: 100px;}
}
.about_ccie_phone{display: none;}

.about_business{margin-top: 100px;
    &_box{
        &_list{margin-bottom: 25px;position: relative;display: grid;
            img{width: 100%;cursor: pointer;}   
        }
    }
    &_t{position: absolute;bottom: 0;left:0;width:calc(100% - 40px);background: rgba(0,0,0,0.3);color: #fff;padding: 20px;cursor: pointer;
        h4{font-weight: normal;margin-bottom: 2px;letter-spacing: 2px;}
        span{font-size: 14px;letter-spacing: 2px;}
    }
}

.about_serve{margin-top: 90px;padding: 50px 0 90px;background: url(../assets/banner/about_serve.png) no-repeat center center;background-size: cover;
    &_box{
        &_list{margin-bottom: 25px;background: #fff;box-shadow:1px 1px 7px rgba(0,0,0,0.01);text-align: center;padding: 60px 0 80px;
            h4{margin-bottom: 2px;margin-bottom: 10px;}
            img{width: 80px;}
        }
        &_list:hover h4{font-weight: bold;color: #0066cb;}
    }
}

.mobile {
    .about_info{
        &_box{
            &_list{justify-content: start;margin-bottom: 25px;}
        }
    }
    .about_ccie_pc{display: none;}
    .about_ccie_phone{display: block;margin-top: 50px;background: linear-gradient(to bottom, #f6f6f6, #c3c3c3);
        &_title{padding-top: 50px;}
        img{width: 100%;}
    }
}

</style>