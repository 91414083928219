<template>
    <div>
      <header-component active="/property" isCurrent="5"></header-component>
      <!-- <product-component></product-component> -->
      <div class="property-content">
            <div class="banner">
                <div class="banner_box animate-text">
                    <div class="banner_box-black">
                    <div class="banner_title banner_big_title">
                        智能停车
                    </div>
                    <div class="banner_subTitle">
                        支持接入本地车场管理系统，获取实时车辆出入数据、支付数据，汇聚于云平台，进行存储与数据报表统计分析，为运营、财务等部门提供更方便、更直观的数据分析图表。
                    </div>
                    </div>
                </div>
            </div>

            <div class="park-tabs">
                <div class="box-width">
                <el-row :gutter="20">
                    <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6" v-for="(item, i) in tabsList" :key="i">
                        <div class="park-tabs-item" :class="tabsList.length == i + 1 ? 'noBorder' : ''">
                            <div class="park-tabs-item-img">
                                <img :src="item.icon" alt="" class="sway">
                            </div>
                            <div class="park-tabs-item-title">{{item.name}}</div>
                        </div>
                    </el-col>
                </el-row>
                </div>
            </div>

            <div class="product-net">
            <div class="property-net">
                <div class="property-net-list isbg">
                    <div class="box-width">
                    <el-row :gutter="20">
                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                            <div class="slide-in-left">
                            <div class="title bigTop">软件产品</div>
                            <div class="details">
                                支持车主自助完成月租续期；支持车主自助开通无感支付；支持 扫码缴费、无牌车扫码出入场；月租即将过期或已过期自动 推送消息提醒车主；提供多种数据报表统计分析车辆行为及支付情况
                            </div>
                            <div class="classify">
                                <el-row :gutter="20">
                                    <el-col :xs="6" :sm="6" :md="4" :lg="4" :xl="4" v-for="(item, index) in mobileList" :key="index">
                                        <div class="classify-hover">
                                            <img :src="item.icon" alt="" class="sway">
                                            <div class="name">{{ item.name }}</div>
                                        </div>
                                    </el-col>
                                </el-row>
                            </div>
                            </div>
                        </el-col>
                        
                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                            <div class="property-net-img slide-in-right" style="width: 80%;">
                                <img src="../../assets/pro/park-mobile.png" alt="">
                            </div>
                        </el-col>
                    </el-row>
                    </div>  
                </div>
            </div>
            </div>

            <div class="park-admin">
                <div class="title">系统架构</div>
                <div class="box-width">
                    <img src="../../assets/pro/park-admin.png" alt="">
                </div>
            </div>
            
      </div>
      <footer-component></footer-component>
    </div>
</template>

<script>
export default {
    name: 'app_Parking',
    data () {
        return {
            tabsList: [
                {icon: require('../../assets/icon/park_icon5.png'), name: '车辆识别一体机'},
                {icon: require('../../assets/icon/park_icon6.png'), name: '高清车牌识别摄像机'},
                {icon: require('../../assets/icon/park_icon7.png'), name: '红绿LED显示屏'},
                {icon: require('../../assets/icon/park_icon8.png'), name: '旋转式底座'},
            ],
            mobileList: [
                {icon: require('../../assets/icon/park_icon1.png'), name: '楼盘管理'},
                {icon: require('../../assets/icon/park_icon2.png'), name: '住户管理'},
                {icon: require('../../assets/icon/park_icon3.png'), name: '收费设置'},
                {icon: require('../../assets/icon/park_icon4.png'), name: '付款管理'},
            ],
        }
    }
}
</script>

<style lang="scss" scoped>
    .banner{background: url(../../assets/banner/parking_b.png) no-repeat 80% center;background-size: cover;height: 557px;
        &_box{width: 30%;padding-top: 5%;}
        &_title{color: #333;margin-bottom: 10px;}
        &_subTitle{color: #333;}
    }
    .property-net .isbg{background: #f4f4f4;}
    .park-tabs{background: #297DD2;font-size: 18px;color: #fff;padding: 36px 0;
        &-item{display: flex;align-items: center;justify-content: center;border-right: 1px solid rgba($color: #fff, $alpha: 0.3);padding: 0 10px;position: relative;}
        .noBorder{border-right: none;}
        img{width: 48px;height: 48px;margin-right: 10px;} 
    }
    .park-admin{padding: 50px 0;;
        .title{margin-bottom: 50px;text-align: center;font-size: 25px;}
        img{width: 80%;margin-left: 10%;}
    }
    .bigTop{margin-top: 180px!important;}
    .mobile {
        .banner{
            &_box{width: 95%;}
            &_title{color: #fff;}
            &_subTitle{color: #fff;}
        }
        .park-tabs{
            &-item{justify-content: start;border-right: none;padding: 0 10px;margin-bottom: 25px;}
        }
        .park-admin{
            img{width: 100%;margin-left: 0;}
        }
        .bigTop{margin-top: 60px!important;}
    }
</style>