<template>
    <div>
      <header-component active="/property" isCurrent="9"></header-component>
      <!-- <product-component></product-component> -->
      <div class="property-content">
            <div class="banner">
                <div class="banner_box animate-text">
                    <div class="banner_box-white">
                    <div class="banner_title banner_big_title">
                        数据中心
                    </div>
                    <div class="banner_subTitle">
                        核心关键数据可视化展示，为管理决策提供数据依据
                    </div>
                    </div>
                </div>
            </div>

            <div class="product-net">
            <div class="property-net">
                <div class="property-net-list box-width" style="padding: 50px 0;">
                    <el-row :gutter="30">
                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                            <div class="property-net-img slide-in-left" style="width: 100%;">
                                <img src="../../assets/pro/echart1.png" alt="">
                            </div>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                            <div class="property-net-text slide-in-right">
                                <div class="title">物业数据看板</div>
                            </div>
                        </el-col>
                    </el-row>  
                </div>

                <div class="property-net-list isbg isbg2">
                    <div class="box-width">
                    <el-row :gutter="30">
                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                            <div class="property-net-text slide-in-left">
                                <div class="title">工程数据看板</div>
                            </div>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                            <div class="property-net-img slide-in-right" style="width: 100%;">
                                <img src="../../assets/pro/echart2.png" alt="">
                            </div>
                        </el-col>
                    </el-row> 
                    </div> 
                </div>

                <div class="property-net-list box-width" style="padding: 50px 0;">
                    <el-row :gutter="30">
                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                            <div class="property-net-img slide-in-left" style="width: 100%;">
                                <img src="../../assets/pro/echart3.png" alt="">
                            </div>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                            <div class="property-net-text slide-in-right">
                                <div class="title">内容数据看板</div>
                            </div>
                        </el-col>
                    </el-row>  
                </div>
                
            </div>
            </div>

            <div class="park-tabs">
                <div class="box-width">
                <el-row :gutter="20">
                    <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" v-for="(item, i) in tabsList" :key="i">
                        <div class="park-tabs-item" :class="tabsList.length == i + 1 ? 'noBorder' : ''">
                            <div class="park-tabs-item-img">
                                <img :src="item.icon" alt="" class="sway">
                            </div>
                            <div class="park-tabs-item-title">{{item.name}}</div>
                        </div>
                    </el-col>
                </el-row>
                </div>
            </div>

            
      </div>
      <footer-component></footer-component>
    </div>
</template>

<script>
export default {
    name: 'app_EchartData',
    data () {
        return {
            tabsList: [
                {icon: require('../../assets/icon/echart_icon1.png'), name: '接入业务系统，采集 关键数据 '},
                {icon: require('../../assets/icon/echart_icon2.png'), name: '分析数据、生成统计报表 '},
                {icon: require('../../assets/icon/echart_icon3.png'), name: '支持条件筛选，查询不同 时间段数据情况 '},
            ],
        }
    }
}
</script>

<style lang="scss" scoped>
    .banner{background: url(../../assets/banner/echart_b.png) no-repeat 80% center;background-size: cover;height: 557px;
        &_box{width: 30%;padding-top: 5%;}
        &_title{color: #fff;margin-bottom: 10px;}
    }
    .isbg2{background: #F7F7F7;}
    .park-tabs{background: #297DD2;font-size: 18px;color: #fff;padding: 36px 0;
        &-item{display: flex;align-items: center;justify-content: center;border-right: 1px solid rgba($color: #fff, $alpha: 0.3);padding: 0 10px;}
        .noBorder{border-right: none;}
        img{width: 48px;height: 48px;margin-right: 10px;}
    }
    .park-admin{padding: 50px 0;;
        .title{margin-bottom: 50px;text-align: center;font-size: 25px;}
        img{width: 80%;margin-left: 10%;}
    }
    .product-net .property-net{
            &-list{min-height: 382px;
                .title{text-align: center;margin-top: 0;}
            }
        }
    .mobile {
        .banner{
            &_box{width: 95%;}
        }
        .park-tabs{
            &-item{justify-content: start;border-right: none;padding: 0 10px;margin-bottom: 25px;}
        }
        .product-net .property-net{
            &-list{min-height: auto;}
            
        }
    }
</style>