import Vue from 'vue'
// import 'amfe-flexible';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import router from './router/router';
import axios from 'axios';

import header from './components/header.vue';
import footer from './components/footer.vue';
import product from './components/product.vue';
import leftFloat from './components/leftFloat.vue';

import './styles/common.scss'

const service = axios.create({
  baseURL: '/api/',
  
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json'
  }
});
service.interceptors.request.use(
  config => {
    return config;
})


Vue.config.productionTip = false
Vue.prototype.$http = service;

Vue.use(ElementUI);
Vue.use(router)

Vue.component('header-component', header)
Vue.component('footer-component', footer)
Vue.component('product-component', product)
Vue.component('leftFloat-component', leftFloat)

new Vue({
  router,
  el: '#app',
  render: h => h(App),
}).$mount('#app')
