<template>
    <div>
      <header-component active="/payment" isCurrent="8"></header-component>
      <!-- <product-component></product-component> -->
      <div class="property-content">
            <div class="banner">
                <div class="banner_box animate-text">
                    <div class="banner_box-black">
                    <div class="banner_title banner_big_title">
                        智能分账
                    </div>
                    <div class="banner_subTitle">
                        统一管理企业支付渠道，安全收款，灵活支付。便捷创建商户信息，解决用户系统配置周期长，收付款操作复杂等面临的问题，满足企业的分账需求，灵活配置分账规则，针对企业交易的安全性和合规性，提供高效合规的支付、分账、清算等服务。
                    </div>
                    </div>
                </div>
            </div>

            <div class="product_strength">
            <div class="property_strength payment_strength">
                <div class="box-width">
                    <div class="property_strength_title public_title">产品功能</div>
                    <el-row :gutter="30">
                        <div class="property_strength_box">
                            <el-col :xs="12" :sm="12" :md="12" :lg="6" :xl="6" v-for="(item,index) in strengthList" :key="index">
                                <div class="property_strength_box_list">
                                    <img :src="item.icon">
                                    <h4></h4>
                                    <div class="property_strength_t">
                                        <span>{{item.info}}</span>
                                    </div>
                                </div>
                            </el-col>
                        </div>
                    </el-row>
                </div>
            </div>

            <div class="property_strength payment_strength2">
                <div class="box-width">
                    <div class="property_strength_title public_title">产品优势</div>
                    <el-row :gutter="25">
                        <div class="property_strength_box">
                            <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6" v-for="(item,index) in strengthList2" :key="index">
                                <div class="property_strength_box_list">
                                    <img :src="item.icon">
                                    <h4>{{item.name}}</h4>
                                    <div class="property_strength_t">
                                        <span>{{item.info}}</span>
                                    </div>
                                </div>
                            </el-col>
                        </div>
                    </el-row>
                </div>
            </div>
            
            </div>

            <div class="property_scene">
                <div class="box-width">
                <div class="property_scene_title public_title">应用场景</div>
                <div class="property_scene_box">
                    <el-row :gutter="25">
                        <el-col :xs="12" :sm="24" :md="12" :lg="6" :xl="6" v-for="item in sceneList" :key="item.id">
                            <div class="property_scene_box_list scale-img">
                                <img :src="item.imgUrl">
                                <div class="property_scene_box_list_t">
                                    {{item.title}}
                                </div>
                            </div>
                            
                        </el-col>
                    </el-row>
                </div>
                </div>
            </div>

            
            
      </div>
      <footer-component></footer-component>
    </div>
</template>

<script>
export default {
    name: 'app_Dangjian',
    data () {
        return {
            strengthList: [
                {icon: require('../../assets/icon/payment_strength1.png'), info: '多渠道支付,兼容多场景支付 '},
                {icon: require('../../assets/icon/payment_strength2.png'), info: '灵活配置收款账户 '},
                {icon: require('../../assets/icon/payment_strength3.png'), info: '支持分账规则配置 '},
                {icon: require('../../assets/icon/payment_strength4.png'), info: '支持接入企业,管理系统提升企业收款能力 '}
            ],
            strengthList2: [
                {icon: require('../../assets/icon/payment_strength5.png'), name: '灵活分账，即开即用', info: '支持自定义分账比例，可按比例或固定金额分账模式，配置实时分账或延期分账，银行账户自动到账。 '},
                {icon: require('../../assets/icon/payment_strength6.png'), name: '规避二清，资金监管', info: '交易资金由持牌机构监管，平台无权限触碰除平台收益外的资金，满足监管合规要求。  '},
                {icon: require('../../assets/icon/payment_strength7.png'), name: '资金监管，安全可控', info: '资金由银行全程监管，银行的账户系统，支持个人和对公账户的管理和配置，监管账户，实现安全支付。 '},
                {icon: require('../../assets/icon/payment_strength8.png'), name: '统一对账，降本增效', info: '交易流水全程自动化，提高财务对账、记账效率，助力企业支付能力的提升。 '}
            ],
            sceneList: [
                {imgUrl: require('../../assets/pro/payment-scene1.png'), title: '银行卡支付'},
                {imgUrl: require('../../assets/pro/payment-scene2.png'), title: 'H5支付'},
                {imgUrl: require('../../assets/pro/payment-scene3.png'), title: '扫码支付'},
                {imgUrl: require('../../assets/pro/payment-scene4.png'), title: 'APP支付'}
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
    .banner{background: url(../../assets/banner/payment_b.png) no-repeat 80% center;background-size: cover;height: 557px;
        &_box{width: 30%;padding-top: 5%;}
        &_title{color: #fff;margin-bottom: 10px;}
        &_subTitle{color: #fff;}
    }
    .payment_strength{background: #fff!important;}
    .product_strength .property_strength_t{width: 60%;margin: 0 auto;text-align: center;}
    .payment_strength2{background: url(../../assets/banner/payment_strength_bg.png)no-repeat center center!important;background-size: cover!important;}
    .mobile {
        .banner{
            &_box{width: 95%;}
        }
        .product_strength .property_strength{background: #f6f6f6;padding: 40px 0 30px;
            &_box{
                &_list{background: #fff;margin-bottom: 20px;height: 210px;padding: 20px 15px;text-align: center;cursor: pointer;transition: all .25s ease-in;
                    img{margin: 0 auto;width: 75px;height: 75px;}
                }
            }
        }
        .product_strength .property_strength_t{width: 100%;}
    }
</style>