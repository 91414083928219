<template>
    <div class="header-fixed">
        <div class="header-box">
            <div class="logo">
                <img src="../assets/logo.png" alt="LOGO">
            </div>
            
            <nav>
                <ul>
                    <li v-for="(item, index) in menuList" :key="index" class="pcNav" :class="{active: active == item.path}" @mouseover="showSubmenuHandle(item, index)" @mouseleave="hideSubmenu(index)">
                        <!-- <router-link :to="item.path">{{item.name}}</router-link> -->
                        <a class="list-pc" @click="gotpPath(item.path, index)">{{item.name}}</a>
                        <div v-if="item.children && showSubMenu && activeSubmenu === index" class="product-pc">
                            <div class="box-width">
                                <div class="product-pc-box-pc">
                                    <div class="product-pc-item-pc scale-img" v-for="(soon, is) in item.children" :key="is">
                                        <router-link class="custom-link" :to="soon.path">
                                            <img :src="require(`@/assets/product-tab/tab${is+1}${isCurrent==is+1?'-active':''}.png`)" alt="" :class="isCurrent==is+1?'imgActive':'img'">
                                            <div :class="isCurrent==is+1?'product-pc-title-pc active':'product-pc-title-pc'">{{soon.name}}</div>
                                        </router-link>
                                    </div>
                                </div>
                                
                            </div>
                        </div>  
                    </li>
                </ul>
            </nav>
            
            <!-- 移动端菜单 -->
            <!-- <div class="moblie-menu"  v-if="showMenu">
                <ul>
                    <li v-for="(item, index) in menuList" :key="index" :class="{active: active == item.path}">
                        <router-link :to="item.path">{{item.name}}</router-link>
                    </li>
                </ul>
            </div> -->
            <el-drawer
                :with-header="false"
                :visible.sync="showMenu"
                direction="ttb"
                custom-class="my-drawer"
                :modal="false"
                append-to-body
                size="98%">
                <div class="moblie-menu" v-if="showMenu">
                <ul>
                    <li v-for="(item, index) in menuList" :key="index" :class="{active: active == item.path}">
                        <div class="ls">
                            <router-link :to="item.path">{{item.name}}</router-link>
                            <span v-if="item.children" @click="toggle"><i class="el-icon-arrow-down" :class="isExpanded?'xuanz':''"></i></span>
                        </div>
                        <div class="product-item" v-if="boxHeight != '0px' && item.children">
                        <div :style="{ height: boxHeight }" v-for="(soon, index) in item.children" :key="index" :class="childrenActivePath==soon.path?'product-list active':'product-list'">
                            <router-link :to="soon.path">
                                <img :src="require(`@/assets/product-tab/tab${index+1}${childrenActivePath==soon.path?'-active':''}.png`)">
                                <div>{{soon.name}}</div>
                            </router-link>
                            
                        </div>
                        </div>
                    </li>
                    
                </ul>
            </div>
            </el-drawer>


            <div class="moblie-wrapper" :class="{'active': showMenu}" @click="openMenu">
                <span :class="{'active': showMenu}"></span>
                <span></span>
                <span></span>
            </div>
            
        </div>
        <leftFloat-component></leftFloat-component>
    </div>
</template>

<script>
export default {
    name: 'header-component',
    props: {
        active: String,
        isCurrent: String
    },
    data() {
        return {
           menuList: [
                {name: '首 页', path: '/'},
                {name: '产品中心', path: '/property',
                    children:[
                        {name: '智能物业', path: '/property'},
                        {name: '智能办公', path: '/oa'},
                        {name: '智能工单', path: '/workorder'},
                        {name: '智能充电', path: '/charging'},
                        {name: '智能停车', path: '/parking'},
                        {name: '智能门禁', path: '/door'},
                        {name: '智能党建', path: '/dangjian'},
                        {name: '智能分账', path: '/payment'},
                        {name: '数据中心', path: '/echartData'},
                        {name: '资产管理', path: '/asset'}
                    ]
                },
                {name: '关于我们', path: '/about'},
            ],
            showMenu: false,
            isExpanded: false,
            boxHeight: '0px',
            childrenActivePath: null,

            menuTimer: null, 
            activeSubmenu: null, 
            showSubMenu: false, 

        }
    },
    created() {
        var pathname = window.location.pathname;
        if(pathname && pathname != '/' && pathname != '/about'){
            this.childrenActivePath = pathname;
            this.isExpanded = true
            this.boxHeight = 'auto';
            // console.log(pathname,'ccc')
        }
    },
    methods: {
        openMenu(){
            this.showMenu = !this.showMenu
        },
        toggle() {
            this.isExpanded = !this.isExpanded;
            this.boxHeight = this.isExpanded ? 'auto' : '0px';
        },
        showSubmenuHandle(item, index) {
            const _this = this;
            clearTimeout(_this.menuTimer)
            if (item.children && item.children.length > 0) {
                // _debounce(function () {
                _this.activeSubmenu = index;
                _this.showSubMenu = true
                // }, 200);
            }
 
        },
 
        hideSubmenu(index) {
            const _this = this;
            if (_this.activeSubmenu === index) {
                _this.menuTimer = setTimeout(() => {
                _this.activeSubmenu = null;
                _this.showSubMenu = false
                }, 200)
            }
        },
        gotpPath(path, index) { 
            if(index != '1'){
                this.$router.push(path);
            }
        },
    },
    computed: {
        
    },
    mounted() {
        
    },
    watch: {
        
    }
}
</script>

<style lang="scss" scoped>
/deep/
.my-drawer {
    top: 60px!important;
    box-shadow: none!important;
}
.moblie-menu ul {
    padding: 20px;
}
.moblie-menu ul li {
    // margin-bottom:20px;
    font-size: 15px;
    padding: 20px 0;
    border-bottom: #ddd solid 1px;

}
.moblie-menu ul li .ls{    
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.product-pc{
    // display: none;
    padding: 30px 0;
    position: fixed;
    width: 100%;
    background: #fff;
    top: 60px;
    left: 0;
    z-index: 1;
    opacity: 0;
    transform: translateY(-90px);
    animation: slide-down .5s forwards;
    &-box-pc{display: flex;justify-content: center;align-items: center;}
    &-item-pc{width: 20%;text-align: center;cursor: pointer;}
    &-item-pc .img{width: 48px;height: 48px;}
    &-item-pc .imgActive{width: 48px;height: 48px;transform: scale(1.2);}
    &-title-pc{font-size: 16px;color: #333;font-weight: normal;
        // &:hover{color: #0099ff;}
    }
    &-title-pc.active{color: #0066CB;}
    
}
@keyframes slide-down {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.product-item{margin-top: 20px;display: flex;justify-content: space-between;align-items: center;flex-wrap: wrap;}
.product-list{
    overflow: hidden;
    transition: height .5s ease-in;
    font-size: 15px;
    width: 33%;
    text-align: center;
    margin-bottom: 15px;
}
.product-list a{color: #333!important;display: block;text-align: center;}
.product-list.active a{color: #0066CB!important;}
.product-list img{
    width: 40px;
}
.xuanz{ transform: rotate(180deg)}

.header-fixed{width: 100%;position: fixed;top: 0;left: 0;z-index: 999;background: #fff;}
.header-box{width: 100%;display: flex;justify-content: space-between;align-items: center;margin: 0 auto;}
.logo{align-items: center;}
.logo{width: 113px;height: 46px;}
.logo img{width: 100%;height: 100%}

nav ul {
  display: flex;justify-content: center;align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
 
nav ul li {
  margin: 60px;
  font-size: 16px;
}
 
nav ul li a {
  color: #333;
}

nav ul li a,.moblie-menu ul li a,.product-list a{color: #333;cursor: pointer;}
// nav ul li a:hover{color: #0066CB;font-weight: bold;cursor: pointer;}
nav ul li.active, nav ul li.active a,.moblie-menu ul li.active, .moblie-menu ul li.active a, .product-list .active a{color: #0066CB;cursor: pointer;}

 
.mobile {
    .header-box {
  /* 当屏幕宽度小于768px时，菜单变为移动端样式 */
        height: 60px;
        width: 95%;
    }
  /* 其他样式根据需求调整 */
  nav{display: none;}
  .moblie-wrapper.active span{background: #0066CB;}
  .moblie-wrapper span{display: block;width: 20px;height: 2px;background: #333;margin-bottom: 5px;}
  .moblie-wrapper span.active{
        transform: rotate(20deg);
        margin-bottom: 5px;
        transition: 0.5s;
    }
}
 
@media (min-width: 768px) {
  .header-box {
    /* 屏幕宽度大于或等于768px时，取消移动端样式 */
    height: 60px;
    width: 70%;
   
  }
}

</style>