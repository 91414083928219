<template>
    <div>
      <header-component active="/property" isCurrent="4"></header-component>
      <!-- <product-component></product-component> -->
      <div class="property-content">
            <div class="banner">
                <div class="banner_box animate-text">
                    <div class="banner_box-black">
                    <div class="banner_title banner_big_title">
                        智能充电
                    </div>
                    <div class="banner_subTitle">
                        提供安全智能充电体验，充电桩标准化工业级设计，多场景简易安装，兼顾多年龄层充电需求，提供云平台数据统计分析，为管理和决策提供数据依据和参考，轻松实现自动化运维。
                    </div>
                    </div>
                </div>
            </div>

            <div class="product-net">
            <div class="property-net">

                <div class="property-net-list box-width">
                    <el-row :gutter="20">
                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="13">
                            <div class="property-net-img slide-in-left" style="width: 90%;">
                                <img src="../../assets/pro/charging-mobile.png" alt="">
                            </div>
                        </el-col>

                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="11">
                            <div class="slide-in-right">
                            <div class="title bigTop">移动客户端</div>
                            <div class="details">
                                小程序平台盖微信、支付宝主流平台<br/>
                                充电操作简单，充值支付安全便捷<br/>
                                支持扫码+刷卡多形式开电，兼顾各年龄层的充电需求<br/>
                                远程操作、断电续充、充满自停、灵活计费<br/>
                                蓝牙充电功能，满足无网络区域的充电需求<br/>
                                充电状态、充电曲线、充电数据实时展示<br/>
                                随时随地掌握充电状态，充电服务更完备，充电体验更优秀
                            </div>
                            <!-- <div class="classify">
                                <el-row :gutter="20">
                                    <el-col :xs="6" :sm="6" :md="4" :lg="4" :xl="4" v-for="(item, index) in mobileList" :key="index">
                                        <div class="classify-hover">
                                            <img :src="item.icon" alt="">
                                            <div class="name">{{ item.name }}</div>
                                        </div>
                                    </el-col>
                                </el-row>
                            </div> -->
                            </div>
                        </el-col>
                    </el-row> 
                </div>
                
                <div class="property-net-list isbg">
                    <div class="box-width">
                    <el-row :gutter="20">
                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                            <div class="slide-in-left">
                            <div class="title">商户端</div>
                            <div class="details">
                                小程序平台盖微信、支付宝主流平台<br/>
                                充电操作简单，充值支付安全便捷<br/>
                                支持扫码+刷卡多形式开电，兼顾各年龄层的充电需求<br/>
                                远程操作、断电续充、充满自停、灵活计费<br/>
                                蓝牙充电功能，满足无网络区域的充电需求<br/>
                                充电状态、充电曲线、充电数据实时展示<br/>
                                随时随地掌握充电状态，充电服务更完备，充电体验更优秀
                            </div>
                            </div>
                        </el-col>
                        
                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                            <div class="property-net-img slide-in-right" style="width: 100%;">
                                <img src="../../assets/pro/charging-pc.png" alt="">
                            </div>
                        </el-col>
                    </el-row>
                    </div>  
                </div>
            </div>
            </div>
            
            <div class="product_strength">
            <div class="property_strength charging_strength">
                <div class="box-width">
                    <div class="property_strength_title public_title">产品优势</div>
                    <el-row :gutter="25">
                        <div class="property_strength_box">
                            <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6" v-for="(item,index) in strengthList" :key="index">
                                <div class="property_strength_box_list">
                                    <img :src="item.icon">
                                    <h4>{{item.name}}</h4>
                                    <div class="property_strength_t">
                                        <span>{{item.info}}</span>
                                    </div>
                                </div>
                            </el-col>
                        </div>
                    </el-row>
                </div>
            </div>
            </div>

            <div class="property_scene">
                <div class="box-width">
                <div class="property_scene_title public_title">应用场景</div>
                <div class="property_scene_box">
                    <el-row :gutter="25">
                        <el-col :xs="12" :sm="24" :md="12" :lg="6" :xl="6" v-for="item in sceneList" :key="item.id">
                            <div class="property_scene_box_list scale-img">
                                <img :src="item.imgUrl">
                                <div class="property_scene_box_list_t">
                                    {{item.title}}
                                </div>
                            </div>
                            
                        </el-col>
                    </el-row>
                </div>
                </div>
            </div>
            
      </div>
      <footer-component></footer-component>
    </div>
</template>

<script>
export default {
    name: 'app_Charging',
    data () {
        return {
            strengthList: [
                {icon: require('../../assets/icon/charging_strength1.png'), name: '多重保护更安全', info: '可设置工单考核规则，根据工单完成情况统计员工分值'},
                {icon: require('../../assets/icon/charging_strength2.png'), name: '安装维修更便捷', info: '有偿服务工单可在线上进行支付收款透明，避免私下交易'},
                {icon: require('../../assets/icon/charging_strength3.png'), name: '运营管理更安全', info: '可统一批量发布多条工单，指派给某个职位的员工'},
                {icon: require('../../assets/icon/charging_strength4.png'), name: '充电体验更优秀', info: '修改工单信息需要审批才生效，规范工单管理流程'}
            ],
            sceneList: [
                {imgUrl: require('../../assets/pro/charging-scene1.png'), title: '物业公司'},
                {imgUrl: require('../../assets/pro/charging-scene2.png'), title: '城中村'},
                {imgUrl: require('../../assets/pro/charging-scene3.png'), title: '公共场所'},
                {imgUrl: require('../../assets/pro/charging-scene4.png'), title: '商超及便利店'}
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
    .banner{background: url(../../assets/banner/charging_b.png) no-repeat 80% center;background-size: cover;height: 557px;
        &_box{width: 30%;padding-top: 5%;}
        &_title{color: #333;margin-bottom: 10px;}
        &_subTitle{color: #333;}
    }
    .property-net .isbg,.property_scene{background: #f6f6f6;}
    .charging_strength{background: #fff!important;}
    .bigTop{margin-top: 150px!important;}
    .mobile {
        .banner{
            &_box{width: 95%;}
            &_title{color: #fff;}
            &_subTitle{color: #fff;}
        }
        .bigTop{margin-top: 60px!important;}
    }
</style>