<template>
    <div class="product box-comtainer">
        <div class="box-width">
            <div class="product-box">
                <div class="product-item" v-for="(item,index) in productlist" :key="index">
                    <router-link class="custom-link" :to="item.path">
                        <img :src="require(`@/assets/product-tab/tab${index+1}${isCurrent==index+1?'-active':''}.png`)" alt="">
                        <div :class="isCurrent==index+1?'product-title active':'product-title'">{{item.name}}</div>
                    </router-link>
                </div>
            </div>
            
        </div>
        <leftFloat-component></leftFloat-component>
    </div>
</template>

<script>
export default {
    name: 'product-component',
    props: {
        isCurrent: String
    },
    data () {
        return {
            productlist:[
                {name: '智能物业', path: '/property'},
                {name: '智能办公', path: '/oa'},
                {name: '智能工单', path: '/workorder'},
                {name: '智能充电', path: '/charging'},
                {name: '智能停车', path: '/parking'},
                {name: '智能门禁', path: '/door'},
                {name: '智能党建', path: '/dangjian'},
                {name: '智能分账', path: '/payment'},
                {name: '数据中心', path: '/echartData'},
                {name: '资产管理', path: '/asset'}
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
    .product{
        padding: 30px 0;
        position: fixed;
        width: 100%;
        background: #fff;
        top: 0;
        z-index: 1;
        &-box{display: flex;justify-content: center;align-items: center;}
        &-item{width: 20%;text-align: center;cursor: pointer;}
        &-item img{width: 58px;height: 58px;}
        &-title{font-size: 16px;color: #333;
            // &:hover{color: #0099ff;}
        }
        &-title.active{color: #0066CB;}
    }
    .mobile{
        .product{padding: 0;
            .box-width{display: none;}
            // &-box{flex-wrap: wrap;}
            // &-item{width: 33%;margin-bottom: 10px;}
            // &-item img{width: 38px;height: 38px;}
            // &-title{font-size: 12px;}
        }
    }
</style>

