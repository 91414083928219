<template>
  <div>
    <header-component active="/"></header-component>
    <div class="box-comtainer">

      <div class="banner" id="indexTotal">
        <div class="banner_box animate-text">
          <div class="banner_title banner_big_title">
            建设智慧社区<br>
            提供高效只能化社区管理工具
          </div>
          <div class="banner_btn">
            <!-- <el-button type="primary" size="small">免费体验</el-button> -->
            <div class="custom-button" @click="experienceDialogVisible = true">免费体验</div>
          </div>
        </div>
      </div> 

      
      <div class="row box-width">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" v-for="(item, index) in indexTotalList" :key="index">
            <div class="row_list">
              <img :src="item.imgUrl">
              <div class="row_t">
                <!-- <h4>{{item.total}}</h4> -->
                <h4 style="display: flex;align-items: center;"><countTo :startVal="0" :endVal="item.total" :duration="3000"></countTo>+</h4>
                <span class="t">{{item.title}}</span>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>

      
     
      <div class="product box-width" id="indexProduct">
        <div class="product_title public_title">产品中心</div>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" v-for="(item, index) in indexProList" :key="index">
            <div class="product_list" @click="goProduct(item.path)">
              <div class="scale-img">
                <img :src="item.imgUrl">
              </div>
              <div class="product_t">
                <h4>{{ item.title }}</h4>
                <span>{{ item.desc }}</span>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>

      <div class="serve" id="indexServe">
        <div class="box-width">
          <div class="serve_title public_title">我们的服务</div>
          <!-- <el-row :gutter="30"> -->
          <div class="serve_box">
          <!-- <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="4" v-for="(item, index) in indexServeList" :key="index"> -->
            <div class="serve_list" v-for="(item, index) in indexServeList" :key="index">
              <img :src="item.imgUrl">
              <h4>{{ item.title }}</h4>
            </div>
          <!-- </el-col> -->
          </div>
          <!-- </el-row> -->
        </div>
      </div>
      
    </div>

    <el-dialog
      title="免费体验"
      top="200px"
      :width="dialogWidth"
      :close-on-click-modal="false"
      :visible.sync="experienceDialogVisible">
      <experienceComponent @experienceVisible="experienceVisible"></experienceComponent>
    </el-dialog>
    <!-- <leftFloat-component></leftFloat-component> -->
    <footer-component></footer-component>
  </div>
  </template>
  
  <script>
  import countTo from 'vue-count-to';
  import experienceComponent  from "../components/experience.vue";

  export default {
    name: 'app',
    data() {
      return {
        experienceDialogVisible: false,
        dialogWidth: '30%',
        indexTotalList: [
          {imgUrl: require('../assets/icon/index_icon1.png'), total: 200, title: '覆盖项目'},
          {imgUrl: require('../assets/icon/index_icon2.png'), total: 500000, title: '服务业主'},
          {imgUrl: require('../assets/icon/index_icon3.png'), total: 15000000, title: '管理面积'},
        ],
        indexProList: [
          {imgUrl: require('../assets/pro/index_pro1.png'), path:'/charging', title: '智能充电', desc: '采用标准化安装组件，建设安全智能的充电桩 解决方案 '},
          {imgUrl: require('../assets/pro/index_pro2.png'), path:'/parking', title: '智能停车', desc: '打造集中式管理平台，提升车场服务效率 '},
          {imgUrl: require('../assets/pro/index_pro3.png'), path:'/door', title: '智能门禁', desc: '兼容多场景联动，稳定运行，保障环境安全 '},
          {imgUrl: require('../assets/pro/index_pro4.png'), path:'/oa', title: '智能OA', desc: '提升协同办公效率，企业数字化升级  '},
          {imgUrl: require('../assets/pro/index_pro5.png'), path:'/property', title: '智能物业', desc: '助力物业管理流程，提升管理效率 '},
          {imgUrl: require('../assets/pro/index_pro6.png'), path:'/workorder', title: '智能工单', desc: '全流程管理监控，优化企业品质服务 '},
          {imgUrl: require('../assets/pro/index_pro7.png'), path:'/dangjian', title: '智能党建', desc: '综合性党建管理平台，提升党务信息化建设 '},
          {imgUrl: require('../assets/pro/index_pro8.png'), path:'/payment', title: '智能分账', desc: '自由配置，灵活支付，统一管理  '},
          // {imgUrl: require('../assets/pro/index_pro9.png'), path:'/echartData', title: '数据中心', desc: '助力企业科学、高效的分析决策  '},
          {imgUrl: require('../assets/pro/index_pro10.png'), path:'/asset', title: '资产管理', desc: '助力企业固定资产生命周期的有效管控  '}
        ],
        indexServeList: [
          {imgUrl: require('../assets/icon/index_serve1.png'), title: '产品设计'},
          {imgUrl: require('../assets/icon/index_serve2.png'), title: '系统开发'},
          {imgUrl: require('../assets/icon/index_serve3.png'), title: '网站建设'},
          {imgUrl: require('../assets/icon/index_serve4.png'), title: '移动应用'},
          {imgUrl: require('../assets/icon/index_serve5.png'), title: '项目运维'},
        ],
        startVal: 0,
        endVal: 2020,
      }
    },
    methods: {
      goProduct(path){
        this.$router.push(path);
      },
      experienceVisible(){
        this.experienceDialogVisible = false;
      },
      initDialogWidth(){
        const width = document.body.clientWidth;
        if(width < 600){
          this.dialogWidth = '90%';
        }
      }
    },
    mounted() {
      this.initDialogWidth();
    },
    watch: {
      '$store.state.dialogWidth': function(val) {
        console.log(val)
        this.dialogWidth = val;
      },
    },
    components: {
      experienceComponent, countTo 
    }
  }
  </script>
  
  <style lang="scss" scoped>
    .banner{background: url(../assets/banner/index_b.png) no-repeat 80% center;background-size: cover;
      &_box{padding-top: 10%;}
    }

    .row{
      position: relative;top: -50px;padding:0 10px;background: linear-gradient(to bottom, #EEF1F9, #fff);border: 1px solid #fff;border-radius: 3px;
      box-shadow:0 3px 5px rgba(0,0,0,0.1);
      &_list{
        display: flex;justify-content: center;align-items: center;
        h4{font-size: 32px;margin:0}
        span{font-size: 16px;color: #666;}
        img{width: 100px;height: 100px;}
      }
      &_t{
        span{font-size: 22px;color: #333;}
        .t{font-size: 16px;color: #666;}
      }
    }

    .product{
      padding-top: 30px;
      &_title{}
      &_list{background: #fff; box-shadow:1px 1px 5px rgba(0,0,0,0.1);border-radius: 3px;padding: 10px;margin-bottom: 30px;cursor: pointer;
        img{width: 100%;height: 252px;}
        h4{font-size: 20px;margin-bottom:10px;margin-top:15px;}
        span{font-size: 16px;color: #666;}
      }
    }

    .serve{
      padding-top: 60px; padding-bottom: 70px;margin-top: 60px;background: url(../assets/banner/index_serve_bg.png) no-repeat center center;background-size: cover;
      &_title{}
      &_box{display: flex;}
      &_list{background: #fff;text-align: center;padding: 20px; box-shadow:1px 1px 7px rgba(0,0,0,0.01);width: 20%;margin-left: 20px;cursor: pointer;transition: all .25s ease-in;
        img{width: 80px;height: 80px;}
        h4{font-size: 16px;margin:0;font-weight: normal;color: #000;}
      }
      &_list:first-child{margin-left: 0;}
      &_list:hover{transform: translateY(-8px);}
      &_list:hover h4{font-weight: bold;color: #0066cb;}
    }

    .mobile {
      
      .row{background: none;border: none;border-radius: 0;box-shadow:none;
        &_list{justify-content: start;background: linear-gradient(to bottom, #EEF1F9, #fff);border: 1px solid #fff;border-radius: 3px;
          box-shadow:0 3px 5px rgba(0,0,0,0.1);margin-bottom: 20px;
          h4{font-size: 22px;color: #333;margin:0}
          span{font-size: 14px;color: #666;}
          img{width: 80px;height: 80px;}
        }
        &_t{
        span{font-size: 22px;color: #333;}
        .t{font-size: 16px;color: #666;}
      }
      }
      .serve{
        &_box{display: flex;flex-wrap: wrap;flex-direction: row;justify-content: space-between;}
        &_list{width: 35%;margin-left: 0;margin-bottom: 25px;}
      }
    }
  </style>
  