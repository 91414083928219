<template>
    <div>
      <header-component active="/property" isCurrent="7"></header-component>
      <!-- <product-component></product-component> -->
      <div class="property-content">
            <div class="banner">
                <div class="banner_box animate-text">
                    <div class="banner_box-black">
                    <div class="banner_title banner_big_title">
                        智能党建
                    </div>
                    <div class="banner_subTitle">
                        集党建宣传、党员教育、党建管理为一体的智慧化管理可视化平台，党员可随时随地浏览党建资讯、进行党课学习，通过图形可视化展现党建信息，能方便直观了解党组织的基本情况和亮点工作，有力提升基层党建的科学化水平
                    </div>
                    </div>
                </div>
            </div>

            <div class="product_strength">
            <div class="property_strength dangjian_strength">
                <div class="box-width">
                    <div class="property_strength_title public_title">产品功能</div>
                    <el-row :gutter="30">
                        <div class="property_strength_box">
                            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" v-for="(item,index) in strengthList" :key="index">
                                <div class="property_strength_box_list">
                                    <img :src="item.icon">
                                    <h4></h4>
                                    <div class="property_strength_t">
                                        <span>{{item.info}}</span>
                                    </div>
                                </div>
                            </el-col>
                        </div>
                    </el-row>
                </div>
            </div>
            </div>
            
      </div>
      <footer-component></footer-component>
    </div>
</template>

<script>
export default {
    name: 'app_Dangjian',
    data () {
        return {
            strengthList: [
                {icon: require('../../assets/icon/dangjian_strength1.png'), info: '支持对党群概况、党员风采、组织架构图等信息进行管理'},
                {icon: require('../../assets/icon/dangjian_strength2.png'), info: '支持发布党建动态资讯、党课学习资料 '},
                {icon: require('../../assets/icon/dangjian_strength3.png'), info: '支持H5显示党建具体信息情况 '},
                {icon: require('../../assets/icon/dangjian_strength4.png'), info: '支持多站点管理，可根据项目用户权限进行看板数据管理 '},
                {icon: require('../../assets/icon/dangjian_strength5.png'), info: '支持根据企业形象、企业文化及定位，定制自有前端展示模板 '},
                {icon: require('../../assets/icon/dangjian_strength6.png'), info: '支持以数据接口形式对接,企业其他业务平台及数据中心 '},
            ],
        }
    }
}
</script>

<style lang="scss" scoped>
    .banner{background: url(../../assets/banner/dangjian_b.png) no-repeat 80% center;background-size: cover;height: 557px;
        &_box{width: 30%;padding-top: 5%;}
        &_title{color: #333;margin-bottom: 10px;}
        &_subTitle{color: #333;}
    }
    .dangjian_strength{background: #fff!important;}
    .product_strength .property_strength_t{width: 70%;margin: 0 auto;text-align: center;}
    .mobile {
        .banner{
            &_box{width: 95%;}
            &_title{color: #fff;}
            &_subTitle{color: #fff;}
        }
        
    }
</style>