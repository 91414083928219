<template>
    <div>
      <header-component active="/property" isCurrent="6"></header-component>
      <!-- <product-component isCurrent="6"></product-component> -->
      <div class="property-content">
            <div class="banner">
                <div class="banner_box animate-text">
                    <div class="banner_box-black">
                    <div class="banner_title banner_big_title">
                        智能门禁
                    </div>
                    <div class="banner_subTitle">
                        门禁关联手机应用，实现了万物相连，借助手机可进行远程呼叫、远程开门、临时密码开门、扫码开门、访客登记等功能
                    </div>
                    </div>
                </div>
            </div>

            <div class="product-net">
            <div class="property-net">
                <div class="property-net-list isbg">
                    <div class="box-width">
                    <el-row :gutter="30">
                        <el-col :xs="24" :sm="24" :md="15" :lg="15" :xl="15">
                            <div class="property-net-img slide-in-left" style="width: 70%;">
                                <img src="../../assets/pro/door1.png" alt="">
                            </div>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="9" :lg="9" :xl="9">
                            <div class="property-net-text slide-in-right">
                                <div class="title">凯云A33</div>
                                <div class="details">
                                    基于Linux内核和文件系统进行多应用独立开发<br/>
                                    采用TCP私有协议进行数据的通信和交互<br/>
                                    使用RTMP流媒体通话保证通信和数据的安全<br/>
                                    金属铝合金外观，用材讲究，防水防尘，防刮耐磨<br/>
                                    标准化安装，多场景快速安装<br/>
                                    支持刷卡+密码多形式开锁<br/>
                                    访客临时密码开门，轻松应对访客<br/>
                                    手机远程操作、远程通话，智能安全又防盗<br/>
                                    远程OTA，高线管理，数据安全不丢失<br/>
                                    为社区、企业提供全面的安全智慧门禁解决方案
                                    
                                </div>
                            </div>
                        </el-col>
                    </el-row> 
                    </div> 
                </div>

                <div class="property-net-list isbg2">
                    <div class="box-width">
                    <el-row :gutter="30">
                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="15">
                            <div class="property-net-text slide-in-left">
                                <div class="title">凯云智能门禁(ZKKJ-FACE-ACS_3399v1) </div>
                                <div class="details">
                                    基于安卓8.1进行多应用独立开发<br/>
                                    采用高性能芯片方案集成的设计<br/>
                                    集成双目摄像头、RFC、蓝牙、WIFI、4G-LTE/FDD-LTE等多功能<br/>
                                    金属铝合金一体化结构设计，防水防尘，防刮耐磨<br/>
                                    标准化简易安装，兼具智能门禁及考勤于一体<br/>
                                    双目活体人脸识别、RFC刷卡、密码服务，多形式开锁<br/>
                                    访客临时密码开门，轻松应对访客<br/>
                                    APP随时随地查看现场，多重安全保障<br/>
                                    高效安全的管理数据，保障数据不丢失
                                </div>
                            </div>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="9">
                            <div class="property-net-img slide-in-right" style="width: 80%;">
                                <img src="../../assets/pro/door2.png" alt="">
                            </div>
                        </el-col>
                    </el-row> 
                    </div> 
                </div>                
            </div>
            </div>
            
      </div>
      <footer-component></footer-component>
    </div>
</template>

<script>
export default {
    name: 'app_Door',
    data () {
        return {
            
        }
    }
}
</script>

<style lang="scss" scoped>
    .banner{background: url(../../assets/banner/door_b.png) no-repeat 80% center;background-size: cover;height: 557px;
        &_box{width: 30%;padding-top: 5%;}
        &_title{color: #333;margin-bottom: 10px;}
        &_subTitle{color: #333;}
    }
    .property-net .isbg{background: url(../../assets/banner/door1_bg.png)no-repeat center;}
    .property-net .isbg2{background: url(../../assets/banner/door2_bg.png)no-repeat center;}
    .mobile {
        .banner{
            &_box{width: 95%;}
            &_title{color: #fff;}
            &_subTitle{color: #fff;}
        }
        
    }
</style>