<template>
    <div>
      <header-component active="/property" isCurrent="2"></header-component>
      <!-- <product-component></product-component> -->
      <div class="oa-content">
            <div class="banner">
                <div class="banner_box animate-text">
                    <div class="banner_box-black">
                    <div class="banner_title banner_big_title">
                        智能办公
                    </div>
                    <div class="banner_subTitle">
                        为企业打造数字化0A办公平台，为企业提高工作效率、加强团队协作、降低管理成本、提高决策效率、保障企业信息安全。
                    </div>
                    </div>
                </div>
            </div>

            <div class="product-net">
            <div class="property-net">
                <div class="property-net-list box-width" style="padding: 50px 0;">
                    <el-row :gutter="30">
                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                            <div class="property-net-img slide-in-left" style="width: 81%;">
                                <img src="../../assets/pro/oa1.png" alt="">
                            </div>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                            <div class="property-net-text slide-in-right">
                                <div class="title bigTop">移动办公端</div>
                                <div class="details">支持移动办公，企业员工可通过手机处理待办事项，加快各流 程节点的处理速度。同时支持手机端发起流程申请，查看消息提醒、通知公告 </div>
                            </div>
                        </el-col>
                    </el-row>  
                </div>

                <div class="property-net-list isbg isbg2">
                    <div class="box-width">
                    <el-row :gutter="30">
                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                            <div class="property-net-text slide-in-left">
                                <div class="title">管理后台</div>
                                <div class="details">设置企业信息和统一管理流程，通过电脑访问管理系统地址，在电脑端处理个人待办事项。后台功能包括行政办公、人事管理、财务管理、采购管理、统计报表、系统管理模快，对企业进行人、事、物的全面管理</div>
                            </div>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                            <div class="property-net-img slide-in-right" style="width: 100%;">
                                <img src="../../assets/pro/oa2.png" alt="">
                            </div>
                        </el-col>
                    </el-row> 
                    </div> 
                </div>

                <div class="property-net-list box-width">
                    <el-row :gutter="30">
                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                            <div class="property-net-img-w100 slide-in-left">
                                <img src="../../assets/pro/oa3.png" alt="">
                            </div>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                            <div class="property-net-text slide-in-right">
                                <div class="title">人事管理</div>
                                <div class="details">员工档案管理，对员工入职、转正、离职进行监控 劳动合同协议管理，续期、到期及时提醒人力成本预算管理，可对人力成本、人员编制进行管理 </div>
                            </div>
                        </el-col>
                    </el-row>  
                </div>

                <div class="property-net-list isbg isbg4">
                    <div class="box-width">
                    <el-row :gutter="30">
                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                            <div class="property-net-text slide-in-left">
                                <div class="title">预算管控</div>
                                <div class="details">预算管理，可生成各部门整年度预算，请款支出预算对应扣减核算管理，可生成支出凭证，对凭证进行管理出纳管理，出纳人员可查看请款支出流程，可操作支付</div>
                            </div>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                            <div class="property-net-img-w100 slide-in-right">
                                <img src="../../assets/pro/oa4.png" alt="">
                            </div>
                        </el-col>
                        
                    </el-row> 
                    </div> 
                </div>

                <div class="property-net-list box-width">
                    <el-row :gutter="30">
                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                            <div class="property-net-img-w100 slide-in-left">
                                <img src="../../assets/pro/oa5.png" alt="">
                            </div>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                            <div class="property-net-text slide-in-right">
                                <div class="title">采购管理</div>
                                <div class="details">供应商管理，可对供应商进行管理维护，可发布物料信息、采购管理，可进行采购下单、发货、签收入库、评价、库存管理，可进行入库、出库、调拨、库存盘点 </div>
                            </div>
                        </el-col>
                    </el-row>  
                </div>
                
            </div> 
            </div> 
            
            <div class="product_strength">
            <div class="property_strength oa_strength">
                <div class="box-width">
                    <div class="property_strength_title public_title">产品优势</div>
                    <el-row :gutter="25">
                        <div class="property_strength_box">
                            <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6" v-for="(item,index) in strengthList" :key="index">
                                <div class="property_strength_box_list">
                                    <img :src="item.icon">
                                    <h4>{{item.name}}</h4>
                                    <div class="property_strength_t">
                                        <span>{{item.info}}</span>
                                    </div>
                                </div>
                            </el-col>
                        </div>
                    </el-row>
                </div>
            </div>
            </div>
            
            
      </div>
      <footer-component></footer-component>
    </div>
</template>

<script>
export default {
    name: 'app_Oa',
    data () {
        return {
            strengthList: [
                {icon: require('../../assets/icon/oa_strength1.png'), name: '移动办公', info: '提供移动办公端，企业员工可通过手机处理待办事项，提高事项处理效率。'},
                {icon: require('../../assets/icon/oa_strength2.png'), name: '流程智能化', info: '可自定义设置流程审批节点、发起流程权限、查看流程权限 '},
                {icon: require('../../assets/icon/oa_strength3.png'), name: '数据权限隔离', info: '系统可根据不同的人设置只能查看某个事业部，或某个片区'},
                {icon: require('../../assets/icon/oa_strength4.png'), name: '多维度报表查询', info: '从不同的纬度统计数据，根据不同的场景可查看不同的报表数据 '}
            ],
        }
    }
}
</script>

<style lang="scss" scoped>
    .banner{background: url(../../assets/banner/oa_b.png) no-repeat 80% center;background-size: cover;height: 557px;
        &_box{width: 30%;padding-top: 5%;}
        &_title{color: #fff;margin-bottom: 10px;}
        &_subTitle{color: #fff;}
    }

    .property-net .isbg2{background: url(../../assets/banner/oa2_bg.png)no-repeat center;}
    .property-net .isbg4{background: url(../../assets/banner/oa4_bg.png)no-repeat center;}
    .oa_strength{background: url(../../assets/banner/oa_strength_bg.png)no-repeat center center!important;background-size: cover!important;}
    .bigTop{margin-top: 250px!important;}
    
    .mobile {
        .banner{
            &_box{width: 95%;}
        }
        .bigTop{margin-top: 60px!important;}
    }
</style>