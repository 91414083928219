<template>
    <div class="footer">
        <div class="footer-content box-width">
            <el-row>
                <el-col :xs="24" :sm="24" :md="8" :lg="7" :xl="7">
                    <div class="footer-content-left">
                        <img src="../assets/logo-white.png" alt="">
                        <div class="mobile-footer-center">
                            <el-collapse accordion>
                                <el-collapse-item title="首页" name="1">
                                    <div class="mobile-item"><router-link to="/" @click.native="currentPage('indexTotal')">数据展示</router-link></div>
                                    <div class="mobile-item"><router-link to="/" @click.native="currentPage('indexProduct')">产品中心</router-link></div>
                                    <div class="mobile-item"><router-link to="/" @click.native="currentPage('indexServe')">我们的服务</router-link></div>
                                </el-collapse-item>
                                <el-collapse-item title="产品中心" name="2">
                                    <div class="mobile-item"><router-link to="/property">智慧物业</router-link></div>
                                    <div class="mobile-item"><router-link to="/workorder">智慧工单</router-link></div>
                                    <div class="mobile-item"><router-link to="/charging">智慧充电</router-link></div>
                                    <div class="mobile-item"><router-link to="/parking">智慧停车</router-link></div>
                                    <div class="mobile-item"><router-link to="/door">智慧门禁</router-link></div>
                                    <div class="mobile-item"><router-link to="/oa">智慧办公</router-link></div>
                                    <div class="mobile-item"><router-link to="/dangjian">智慧党建</router-link></div>
                                    <div class="mobile-item"><router-link to="/payment">智慧支付</router-link></div>
                                    <div class="mobile-item"><router-link to="/echartData">数据中心</router-link></div>
                                    <div class="mobile-item"><router-link to="/asset">资产管理</router-link></div>
                                </el-collapse-item>
                                <el-collapse-item title="关于我们" name="3">
                                    <div class="mobile-item"><router-link to="/about" @click.native="currentPage('aboutInfo')">公司简介</router-link></div>
                                    <div class="mobile-item"><router-link to="/about" @click.native="currentPage('aboutInfo')">公司文化</router-link></div>
                                    <div class="mobile-item"><router-link to="/about" @click.native="currentPage('aboutCcie')">公司资质</router-link></div>
                                    <div class="mobile-item"><router-link to="/about" @click.native="currentPage('aboutBusiness')">经营范围</router-link></div>
                                    <div class="mobile-item"><router-link to="/about" @click.native="currentPage('aboutServe')">服务支持</router-link></div>
                                </el-collapse-item>
                            </el-collapse>
                        </div>
                        <div class="info">
                            <p>联系电话：</p>
                            <h5>180-7818-7508（客户经理）</h5>
                            <p>邮箱：liyuqi@kaiyun.tech</p>
                            <p>工作时间：08:30-18:00（法定工作日）</p>
                            <p>地址：南宁市兴宁区望州南路237号</p>
                        </div>
                    </div>
                </el-col>

                <el-col :xs="24" :sm="24" :md="14" :lg="15" :xl="15">
                    <div class="footer-content-center">
                        <ul>
                            <li><h5>首页</h5></li>
                            <li><router-link to="/" @click.native="currentPage('indexTotal')">数据展示</router-link></li>
                            <li><router-link to="/" @click.native="currentPage('indexProduct')">产品中心</router-link></li>
                            <li><router-link to="/" @click.native="currentPage('indexServe')">我们的服务</router-link></li>
                        </ul>
                        <ul>
                            <li><h5>产品中心</h5></li>
                            <li><router-link to="/property">智慧物业</router-link></li>
                            <li><router-link to="/workorder">智慧工单</router-link></li>
                            <li><router-link to="/charging">智慧充电</router-link></li>
                            <li><router-link to="/parking">智慧停车</router-link></li>
                            <li><router-link to="/door">智慧门禁</router-link></li>
                        </ul>
                        <ul>
                            <li><router-link to="/oa">智慧办公</router-link></li>
                            <li><router-link to="/dangjian">智慧党建</router-link></li>
                            <li><router-link to="/payment">智慧支付</router-link></li>
                            <li><router-link to="/echartData">数据中心</router-link></li>
                            <li><router-link to="/asset">资产管理</router-link></li>
                        </ul>
                        <ul>
                            <li><h5>关于我们</h5></li>
                            <li><router-link to="/about" @click.native="currentPage('aboutInfo')">公司简介</router-link></li>
                            <li><router-link to="/about" @click.native="currentPage('aboutInfo')">公司文化</router-link></li>
                            <li><router-link to="/about" @click.native="currentPage('aboutCcie')">公司资质</router-link></li>
                            <li><router-link to="/about" @click.native="currentPage('aboutBusiness')">经营范围</router-link></li>
                            <li><router-link to="/about" @click.native="currentPage('aboutServe')">服务支持</router-link></li>
                        </ul>
                    </div>
                </el-col>

                <el-col :xs="24" :sm="24" :md="2" :lg="2" :xl="2">
                    <div class="footer-content-right">
                        <img src="../assets/qrCode.png" alt="">
                        <p>关注众凯科技</p>
                    </div>
                </el-col>
            </el-row>
            <div class="footer-content-bottom">
                备案号：桂ICP备15009217号
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'footer-component',
    data() {
        return {
            
        }
    },
    methods: {
        currentPage(element){
            console.log(element,'id')
            const scrollTop = document.getElementById(element);
            scrollTop.scrollIntoView();
        }
    }
}
</script>
<style lang="scss" scoped>
.footer {
    padding: 20px 0 30px;
    background-color: #333;
    .mobile-footer-center{display: none;}
    .footer-content {
        color: #999;
        font-size: 13px;


        .footer-content-left {
            h5{color: #fff;margin-bottom: 10px;font-size: 15px;font-weight: normal;}
            .info {
                margin-top: 15px;
                p:first-child{margin-bottom: 5px;}
                p{margin-bottom: 6px;}
            }
            img{
                width: 140px;
                height: 57px;
            }
        }
        .footer-content-center {
            display: flex;
            ul li{margin-bottom: 10px;}
            ul li h5{margin-bottom: 10px;color: #fff;font-size: 14px;font-weight: normal;}
            ul li a{color: #999;}
            ul li a:hover{color: #fff;}
            ul{margin-right: 50px;}
            ul:nth-child(2){margin-right: 10px;}
            ul:nth-child(3){margin-top: 43px;}
            ul:last-child{margin-right: 0
                
            }
        }
        .footer-content-right {
            margin-top: 15px;
            text-align: center;
            img{
                width: 90px;
                height: 90px;
            }
        }
        .footer-content-bottom {
            border-top: 1px solid #999;
            padding-top: 10px;
           text-align: center;
           margin-top: 40px;
        }
    }
}
.mobile{
    .footer {
        .mobile-footer-center{display: block;
            // 折叠面板
            /deep/.el-collapse {
            background-color: #333 !important;
            }
            /deep/.el-collapse,.el-collapse-item__wrap {
            border: none;
            background-color: #333 !important;
            }
            /deep/ .el-collapse-item__header {
                color: #fff;
                border-bottom: 1px solid #555;
                background-color: #333 !important;
            }
            /deep/ .el-collapse-item__content {
                color: #fff;
                background-color: #333 !important;
                padding-top: 10px;
                padding-bottom: 10px;
            }
            .mobile-item{padding: 6px;
                a{color: #fff;text-decoration: none;}
            }
        }
        .footer-content{
            .footer-content-center {display: none;
                ul{margin-right: 25px;padding: 0;}
            }
        }
        .footer-content-right {
            text-align: left;
        }
    }
}
</style>