<template>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="公司名称" prop="companyName">
            <el-input v-model="ruleForm.companyName" placeholder="请输入公司名称"></el-input>
        </el-form-item>
        <el-form-item label="姓名" prop="personName">
            <el-input v-model="ruleForm.personName" placeholder="请输入您的姓名"></el-input>
        </el-form-item>
        <el-form-item label="手机号码" prop="personPhone">
            <el-input type="number" v-model="ruleForm.personPhone" placeholder="请输入您的手机号码"></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="smsCode">
            <el-col :xs="24" :sm="24" :md="15" :lg="15" :xl="15">
                <el-input type="number" v-model="ruleForm.smsCode" placeholder="请输入验证码"></el-input>
            </el-col>
            
            <el-col :xs="24" :sm="24" :md="7" :lg="7" :xl="7" :push="2" >
                <el-button type="primary" plain size="medium" @click="sendSms" :disabled="isSending">
                    {{ isSending ? countdownText : '发送验证码' }} 
                </el-button>
            </el-col>
        </el-form-item>
        <el-form-item>
            <el-col :xs="9" :sm="4" :md="4" :lg="4" :xl="9" :offset="15" style="text-align: right;">
                <el-button type="primary" size="medium" @click="submitForm('ruleForm')">提 交</el-button>
            </el-col>
        </el-form-item>
    </el-form>
</template>
<script>
export default {
    name: 'app_experience',
    data () {
        return {
            ruleForm: {},
            rules: {
                companyName: [
                    { required: true, message: '请输入公司名称', trigger: 'blur' },
                ],
                personName: [
                    { required: true, message: '请输入您的姓名', trigger: 'blur' },
                ],
                personPhone: [
                    { required: true, message: '请输入您的联系方式', trigger: 'blur' },
                ],
                smsCode: [
                    { required: true, message: '请输入短信验证码', trigger: 'blur' },
                ],
            },
            countdown: 0,
            isSending: false, 
        }
    },
    computed: {  
        countdownText() {  
        if (this.countdown > 0) {  
            return `重新发送(${this.countdown}s)`;  
        } else {  
            return '发送验证码';  
        }  
        },  
    },  
    methods: {
        sendSms() {  
            if(!this.ruleForm.personPhone){
                this.$message.warning('请输入手机号码');
                return;
            }
            if (!this.isSending) {  
                this.isSending = true;  
                this.countdown = 60; // 设置倒计时为60秒  
                this.startCountdown();  
                
                this.$http.post('/user/mobile/sms/send', { phone: this.ruleForm.personPhone }).then(res => {
                    if(res.data.code == 0){
                        this.$message.success(res.data.msg);
                    }else{
                        this.$message.error(res.data.msg);
                        this.isSending = false; 
                        this.countdown = 0; 
                        this.startCountdown(); 
                        this.isSending = false;
                    }
                }).catch(error => {
                    this.$message.error(error.msg);
                });

            }  
        },  
        startCountdown() {  
            if (this.countdown > 0) {  
                setTimeout(() => {  
                    this.countdown--;  
                    this.startCountdown(); // 递归调用自身以实现倒计时  
                }, 1000);  
            } else {  
                this.isSending = false; // 当倒计时结束时，允许重新发送验证码  
            }  
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
            if (valid) {
                console.log(this.ruleForm)
                let data = {
                    topicId: '1783790261433466881',
                    topicName: '系统咨询',
                    ...this.ruleForm
                }
                this.$http.post('/admin/sys/intention/record/h5/save', data).then(res => {
                    if(res.data.code == 0){
                        this.$message.success('请耐心等待，我们会尽快与您联系');
                        setTimeout(()=>{
                            this.$emit('experienceVisible')
                        },1000)
                    }else{
                        this.$message.error(res.data.msg);
                        
                    }
                }).catch(error => {
                    this.$message.error(error.msg);
                });
                
                
            } else {
                console.log('error submit!!');
                return false;
            }
            });
        },
    },
}
</script>